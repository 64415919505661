import React, {  Component } from 'react';
import AdvantagesItem from './AdvantagesItem';

class Advantages extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const cols = this.props.cols;

        return (
            <div className="container advantages">
                <div className="row">
                    { (this.props.data ? this.props.data.map( (item, i) =>
                        <AdvantagesItem key={item.icon.wordpress_id} iterate={i} data={item} cols={cols}/>
                    ) : null)} 
                </div>
            </div>
        );
    }
}

export default Advantages;