import React, { Component } from "react"

class AdvantageItem extends Component {

    render() {
        return (
            <div className={'col-lg-' + this.props.cols } data-aos="slide_up" data-aos-duration="800">
                <div className="advantages__box">
                    <img src={ this.props.data.icon.url.localFile.publicURL } alt={ this.props.data.icon.alt } />
                    <span dangerouslySetInnerHTML={{ __html: this.props.data.text }}></span>
                </div>
            </div>
        );
    }
}

export default AdvantageItem;
